import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
// Material UI
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from '@material-ui/core/InputBase';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
// GraphQL Queries
import slackDestination from '../../graphql/queries/SlackDestination.graphql';
// Lib Shared
import DataTypeIcon from '../../icons/DataType';
import DestinationIcon from '../../icons/Destination';
import semblyLogo from '../../assets/sembly-logo.svg';
import slackLogo from '../../assets/integration-slack.svg';
import FilterIcon from '../../icons/Filter';
import { AutomationRuleCard, GenericDialog, Tags } from '../../components';
import { Pound, PrivateChannel } from '../../icons';
import { RULE_STATUSES, MEETING_TYPES } from '../../constants';
import {
  AddSlackMeetingNotesRuleSettingInput,
  EditSlackMeetingNotesRuleSettingInput,
  GenericSlackDestination,
  IntegrationConnectRules,
  MeetingTypes,
  SlackDestination,
} from '../../types';

/* #region  Types */
interface SlackAutomationRuleCreatorDialogForm {
  description: string;
  destination: GenericSlackDestination | null;
  id: string;
  isActive: boolean;
  keywords: string[];
  meetingType: MeetingTypes;
  rule: keyof typeof IntegrationConnectRules;
}
/* #endregion */

interface SlackAutomationRuleCreatorDialogProps {
  editRule: EditSlackMeetingNotesRuleSettingInput | null;
  onClose: () => void;
  onCreate: (formValues: AddSlackMeetingNotesRuleSettingInput) => void;
  onEdit: (formValues: EditSlackMeetingNotesRuleSettingInput) => void;
}

export const SlackAutomationRuleCreatorDialog: React.VFC<SlackAutomationRuleCreatorDialogProps> = ({
  editRule,
  onClose,
  onCreate,
  onEdit,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showDestinationDialog, setShowDestinationDialog] = useState(false);

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<SlackAutomationRuleCreatorDialogForm>({
    mode: 'all',
    defaultValues: {
      description: 'Slack Meeting Notes Automation',
      destination: null,
      id: '',
      isActive: true,
      keywords: [],
      meetingType: MeetingTypes.GENERIC,
      rule: 'ALL_MEETINGS',
    },
  });
  /* #endregion */

  /* #region  Apollo Hooks */
  const [getDestinations, { data: destinations, loading: isDestinationsLoading }] =
    useLazyQuery<SlackDestination>(slackDestination);
  /* #endregion */

  /* #region Handlers */
  const handleToggleAutocomplete = (state: boolean) => () => {
    setOpenAutocomplete(state);
  };

  const handleChangeTags = async (
    event: unknown,
    allTagsWithNewOne: (string | string[])[],
    reason: AutocompleteChangeReason,
  ) => {
    const tags = allTagsWithNewOne.map((tag) => tag.toString());
    if (tags.length && reason !== 'remove-option') {
      setValue('keywords', [...tags]);
    }
    clearErrors('keywords');
  };

  const handleDeleteTag = async (itemToDelete: string) => {
    const id = getValues('keywords').find((item) => item === itemToDelete);

    if (!id) return;

    setValue(
      'keywords',
      getValues('keywords').filter((item) => item !== id),
    );
  };

  const handleChangeValue = (event: unknown, value: GenericSlackDestination | null | string) => {
    if (value) {
      setValue('destination', value as GenericSlackDestination | null);
      clearErrors('destination');
    } else {
      setValue('destination', null);
    }
  };

  const handleCloseRuleDialog = () => {
    onClose();
    reset();
  };

  const handleClickSubmit = (formValues: SlackAutomationRuleCreatorDialogForm) => {
    if (!formValues.destination?.slackId) {
      throw new Error('Slack Destination ID must be defined');
    }

    if (editRule) {
      onEdit({
        description: formValues.description,
        destinationSlackId: formValues.destination.slackId,
        id: formValues.id,
        isActive: formValues.isActive,
        keywords: formValues.keywords,
        meetingType: formValues.meetingType,
        rule: IntegrationConnectRules[formValues.rule],
      });
    } else {
      onCreate({
        description: formValues.description,
        destinationSlackId: formValues.destination.slackId,
        isActive: formValues.isActive,
        keywords: formValues.keywords,
        meetingType: formValues.meetingType,
        rule: IntegrationConnectRules[formValues.rule],
      });
    }
    handleCloseRuleDialog();
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    if (!destinations) getDestinations();
  }, [destinations, getDestinations]);

  useEffect(() => {
    if (editRule && destinations?.slackDestinations && !isDestinationsLoading) {
      const currentDestination = destinations.slackDestinations.find(
        (item) => item.slackId === editRule.destinationSlackId,
      );

      setValue('description', editRule.description);
      setValue('destination', currentDestination!);
      setValue('id', editRule.id);
      setValue('isActive', editRule.isActive);
      setValue('keywords', editRule.keywords as string[]);
      setValue('meetingType', editRule.meetingType || MeetingTypes.GENERIC);
      setValue('rule', editRule.rule);
    } else {
      setValue('description', 'Slack Meeting Notes Automation');
    }
  }, [editRule, setValue, destinations, isDestinationsLoading]);
  /* #endregion */

  /* #region Render Helpers */
  const currentRule = watch('rule');
  const currentDestination = watch('destination');
  const slackDestinations = destinations?.slackDestinations || [];

  const currentFilterValue = () => {
    let result: string[] = [];
    if (currentRule) result.push(RULE_STATUSES[currentRule]);
    if (currentRule === 'FILTERED_BY_KEYWORDS') {
      const keywords = getValues('keywords');
      if (keywords.length) {
        result.push(`Keywords: ${keywords.join(', ')}`);
      } else {
        result = [];
      }
    }

    return result.length ? result.join('; ') : null;
  };

  // Convert MEETING_TYPES to array save, category => isCategory true;
  const meetingTypeOptions: { label: string; value: string | null; isCategory: boolean }[] =
    MEETING_TYPES.flatMap(({ category, elements }) => [
      { label: category, value: null, isCategory: true },
      ...elements.map((element) => ({
        label: element.label,
        value: element.value,
        isCategory: false,
      })),
    ]);
  /* #endregion */

  return (
    <GenericDialog
      hideTitle
      hideCloseIconButton
      disableTransition
      dialogProps={{ open: true, fullScreen: true, style: { zIndex: 1000 } }}
      onClose={handleCloseRuleDialog}
    >
      <DialogContent className={styles.dialogContent}>
        <div className={styles.header}>
          <img src={semblyLogo} alt="Sembly" className={styles.logo} />
        </div>
        <div className={styles.content}>
          <div className={styles.appLogo}>
            <img src={slackLogo} alt="Slack Integration" width={64} height={64} />
          </div>
          <form className={styles.form} onSubmit={handleSubmit(handleClickSubmit)}>
            <Box position="relative">
              <Tooltip arrow title="Enter Automation Name">
                <InputBase
                  autoFocus
                  placeholder="Enter Automation Name"
                  classes={{ root: styles.fieldRoot, input: styles.fieldInput }}
                  inputProps={{ maxLength: 255, 'aria-label': 'Enter Automation Name' }}
                  {...register('description', {
                    required: true,
                    maxLength: 255,
                    pattern: /[\w,./_=?-]+/,
                  })}
                />
              </Tooltip>
            </Box>

            <div className={styles.steps}>
              <AutomationRuleCard
                disabled
                title="1. Data Type"
                description="What would you like to send: notes, tasks, or transcriptions?"
                value="Meeting Notes"
                icon={<DataTypeIcon color="action" />}
              />
              <div className={styles.delimiter} role="presentation">
                —
              </div>
              <AutomationRuleCard
                title="2. Filter"
                description="Which meetings group should this be applied to?"
                icon={<FilterIcon color="action" />}
                value={currentFilterValue()}
                onClick={() => setShowFilterDialog(true)}
              />
              <div className={styles.delimiter} role="presentation">
                —
              </div>
              <AutomationRuleCard
                title="3. Destination"
                description="Which destination would you like to use for sending this information?"
                icon={<DestinationIcon color="action" />}
                value={!!currentDestination ? currentDestination.name : null}
                onClick={() => setShowDestinationDialog(true)}
              />
            </div>

            <GenericDialog
              title="Filter meetings"
              dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                keepMounted: true,
                disablePortal: true,
                open: showFilterDialog,
              }}
              onClose={() => setShowFilterDialog(false)}
            >
              <DialogContent>
                <Box mb={2}>
                  <Typography variant="h6" className={styles.labelRule}>
                    Rule
                  </Typography>
                  <Controller
                    name="rule"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={getValues('rule')}
                    render={({ field }) => (
                      <FormControl fullWidth size="small" variant="filled">
                        <Select fullWidth {...field}>
                          {Object.entries(RULE_STATUSES).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
                {currentRule === 'FILTERED_BY_KEYWORDS' && (
                  <Box mb={2}>
                    <Typography variant="h6" className={styles.labelRule}>
                      Keywords in the meeting title
                    </Typography>
                    <Controller
                      name="keywords"
                      control={control}
                      defaultValue={getValues('keywords')}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Tags
                          {...field}
                          all={getValues('keywords')}
                          onChangeTags={handleChangeTags}
                          onDelete={handleDeleteTag}
                        />
                      )}
                    />
                    {formState.errors.keywords?.type === 'required' && (
                      <FormHelperText className={styles.validationErrorText} variant="outlined">
                        Keywords is required
                      </FormHelperText>
                    )}
                  </Box>
                )}
                {currentRule === 'FILTERED_BY_MEETING_TYPE' && (
                  <Box mb={2}>
                    <Typography variant="h6" className={styles.labelRule}>
                      Meeting Type
                    </Typography>
                    <Controller
                      name="meetingType"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={getValues('meetingType')}
                      render={({ field }) => (
                        <FormControl fullWidth size="small" variant="filled">
                          <Select fullWidth {...field}>
                            {meetingTypeOptions.map((item, index) =>
                              item.isCategory ? (
                                <ListSubheader
                                  key={index}
                                  classes={{ root: styles.listSubHeaderRoot }}
                                >
                                  {item.label}
                                </ListSubheader>
                              ) : (
                                <MenuItem key={index} value={item.value || ''}>
                                  {item.label}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Box>
                )}
              </DialogContent>
              <DialogActions className={styles.actions}>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  onClick={() => setShowFilterDialog(false)}
                >
                  Done
                </Button>
              </DialogActions>
            </GenericDialog>

            <GenericDialog
              title="Destination"
              dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                keepMounted: true,
                disablePortal: true,
                open: showDestinationDialog,
              }}
              onClose={() => setShowDestinationDialog(false)}
            >
              <DialogContent>
                <Box mb={2}>
                  <Typography variant="h6" className={styles.labelRule}>
                    Channel
                  </Typography>
                  <Controller
                    name="destination"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={getValues('destination')}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        size="small"
                        open={openAutocomplete}
                        loading={isDestinationsLoading}
                        options={slackDestinations}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.slackId === value.slackId}
                        onOpen={handleToggleAutocomplete(true)}
                        onClose={handleToggleAutocomplete(false)}
                        {...register('destination', { required: true })}
                        onChange={handleChangeValue}
                        renderOption={({ name, type }) => (
                          <Box className={styles.channels}>
                            {type === 'PUBLIC_CHANNEL' ? <Pound /> : <PrivateChannel />}
                            <ListItemText primary={name} />
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            ref={params.InputProps.ref}
                            fullWidth
                            variant="filled"
                            size="small"
                            placeholder="Select Slack channel"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {formState.errors.destination?.type === 'required' && (
                    <FormHelperText className={styles.validationErrorText} variant="outlined">
                      Destination is required
                    </FormHelperText>
                  )}
                </Box>
              </DialogContent>
              <DialogActions className={styles.actions}>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  onClick={() => setShowDestinationDialog(false)}
                >
                  Done
                </Button>
              </DialogActions>
            </GenericDialog>

            {!!formState.errors && (
              <Box my={2} display="flex" flexDirection="column" gridGap={6}>
                {formState.errors.description?.type === 'required' && (
                  <Alert color="warning">
                    <Typography variant="body1">Automation Name is required</Typography>
                  </Alert>
                )}
                {formState.errors.description?.type === 'maxLength' && (
                  <Alert color="warning">
                    <Typography variant="body1">
                      Automation Name cannot be longer than 255 characters
                    </Typography>
                  </Alert>
                )}
                {formState.errors.description?.type === 'pattern' && (
                  <Alert color="warning">
                    <Typography variant="body1">Please enter a valid Automation Name</Typography>
                  </Alert>
                )}
                {formState.errors.keywords?.type === 'required' && (
                  <Alert color="warning">
                    <Typography variant="body1">Keywords is required</Typography>
                  </Alert>
                )}
                {formState.errors.destination?.type === 'required' && (
                  <Alert color="warning">
                    <Typography variant="body1">Destination is required</Typography>
                  </Alert>
                )}
              </Box>
            )}

            <div className={styles.dialogActions}>
              <Box flexGrow={1}>
                <Button variant="outlined" onClick={onClose}>
                  Back
                </Button>
              </Box>
              <Box display="flex" flexGrow={0} gridGap={8}>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isDestinationsLoading}
                >
                  Complete
                </Button>
              </Box>
            </div>
          </form>
        </div>
      </DialogContent>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: 0,
  },
  paper: {
    minWidth: '40ch',
    maxWidth: '536px',
    maxHeight: '60vh',
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    borderRadius: theme.shape.borderRadius,
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  destinationHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  destinationInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.75),
  },
  destinationSearch: {
    padding: theme.spacing(1.5, 2),
  },
  contentRule: {
    padding: theme.spacing(0, 4, 4, 4),
  },
  labelRule: {
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1),
  },
  page: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  icon: {
    width: 18,
    height: 18,
    display: 'flex',
  },
  iconButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
    '&.destination': {
      position: 'absolute',
      top: '50%',
      left: theme.spacing(1),
      transform: 'translateY(-50%)',
    },
  },
  validationErrorText: {
    color: theme.palette.status.error.color,
  },
  textWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  form: {
    width: 'inherit',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1, 4, 4),
  },
  actions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2, 3),
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    flex: 1,
  },
  appLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 128,
    width: 128,
  },
  logo: {
    flexGrow: 0,
    width: 160,
    height: 48,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 20,
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
    },
  },
  steps: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    flexDirection: 'row',
    gap: theme.spacing(2),
    margin: theme.spacing(4, 0),
  },
  delimiter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  fieldRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px dashed ${theme.palette.divider}`,
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    ...theme.typography.h1,
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  fieldInput: {
    textAlign: 'center',
  },
  channels: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  rulesBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  listSubHeaderRoot: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

export default SlackAutomationRuleCreatorDialog;
