import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useQuery, useLazyQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
// Material UI
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';
// Material UI Icons
import AddIcon from '@material-ui/icons/AddSharp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BackIcon from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/CloseSharp';
import PageIcon from '@material-ui/icons/Description';
import StorageIcon from '@material-ui/icons/StorageSharp';
// GraphQL Queries
import createNotionDatabaseMutation from '../../graphql/mutations/CreateNotionDatabase.graphql';
import notionDatabases from '../../graphql/queries/NotionDatabases.graphql';
import notionPages from '../../graphql/queries/NotionPages.graphql';
import suggestedNotionDestinationQuery from '../../graphql/queries/SuggestedNotionDestination.graphql';
// Lib Shared
import { AutomationRuleCard, GenericDialog, Tags, Twemoji } from '../../components';
import { RULE_STATUSES, KEY_ITEMS_LIST, MEETING_TYPES } from '../../constants';
import {
  ActionsCustomIntegrationSettingsContentTypes,
  AddNotionAssignmentsRuleSettingInput,
  CreateNotionDatabase,
  CreateNotionDatabaseVariables,
  EditNotionAssignmentsRuleSettingInput,
  GenericNotionDatabase,
  GenericNotionPage,
  GraphError,
  IntegrationConnectRules,
  MeetingTypes,
  NotionDatabases,
  NotionDatabasesVariables,
  NotionIntegrationsTypes,
  NotionPages,
  NotionPagesVariables,
  PlanIDEnum,
  SuggestedNotionDestination,
  SuggestedNotionDestinationVariables,
} from '../../types';
// Lib Assets & Icons
import DataTypeIcon from '../../icons/DataType';
import DestinationIcon from '../../icons/Destination';
import FilterIcon from '../../icons/Filter';
import notionLogo from '../../assets/integration-notion.svg';
import semblyLogo from '../../assets/sembly-logo.svg';

/* #region  Types */
interface NotionAutomationRuleCreatorDialogForm {
  contentTypes: ActionsCustomIntegrationSettingsContentTypes;
  database: GenericNotionDatabase | null;
  description: string;
  id: string;
  isActive: boolean;
  keywords: string[];
  meetingType: MeetingTypes;
  page: GenericNotionPage | null;
  rule: keyof typeof IntegrationConnectRules;
}

export interface NotionAssignmentsAutomationSettings
  extends Omit<
    NonNullable<EditNotionAssignmentsRuleSettingInput>,
    'destinationDatabaseId' | 'destinationPageId'
  > {
  database: GenericNotionDatabase;
  page: GenericNotionPage;
}

export interface NotionAssignmentsAutomationRuleCreatorDialogProps {
  editRule: NotionAssignmentsAutomationSettings | null;
  paymentPlan: PlanIDEnum;
  onClose: () => void;
  onCreate: (formValues: AddNotionAssignmentsRuleSettingInput) => void;
  onEdit: (formValues: EditNotionAssignmentsRuleSettingInput) => void;
  onResponsesError: (err: GraphError) => void;
}
/* #endregion */

export const NotionAssignmentsAutomationRuleCreatorDialog: React.VFC<
  NotionAssignmentsAutomationRuleCreatorDialogProps
> = ({ editRule, paymentPlan, onCreate, onClose, onEdit, onResponsesError }) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedNotionPage, setSelectedNotionPage] = useState<GenericNotionPage | null>(null);
  const [notionPageFilterValue, setNotionPageFilterValue] = useState<string>('');
  const [notionDbFilterValue, setNotionDbFilterValue] = useState<string>('');
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showDestinationDialog, setShowDestinationDialog] = useState(false);

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    setError,
    watch,
  } = useForm<NotionAutomationRuleCreatorDialogForm>({
    mode: 'all',
    defaultValues: {
      database: null,
      description: 'Notion Assignments Automation',
      id: '',
      isActive: true,
      keywords: [],
      meetingType: MeetingTypes.GENERIC,
      page: null,
      rule: IntegrationConnectRules.ALL_MEETINGS,
      contentTypes: ActionsCustomIntegrationSettingsContentTypes.ACTIONS,
    },
  });

  const { loading: isLoadingSuggestedDestination } = useQuery<
    SuggestedNotionDestination,
    SuggestedNotionDestinationVariables
  >(suggestedNotionDestinationQuery, {
    fetchPolicy: 'network-only',
    skip: !!editRule,
    variables: { integrationType: NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION },
    onCompleted(data) {
      if (data.suggestedNotionDestination) {
        setValue('database', data.suggestedNotionDestination.destination.database);
        setValue('page', data.suggestedNotionDestination.destination.page);
      }
    },
  });

  const {
    data: pagesData,
    networkStatus: pagesNetworkStatus,
    fetchMore: fetchMorePages,
    refetch: refetchPages,
  } = useQuery<NotionPages, NotionPagesVariables>(notionPages, {
    variables: {
      pageSize: 15,
      integrationType: NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [
    getDatabases,
    { data: databasesData, networkStatus: dbNetworkStatus, refetch: refetchDatabases },
  ] = useLazyQuery<NotionDatabases, NotionDatabasesVariables>(notionDatabases, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [createNotionDatabase, { loading: isCreatingNotionDb }] = useMutation<
    CreateNotionDatabase,
    CreateNotionDatabaseVariables
  >(createNotionDatabaseMutation);
  /* #endregion */

  /* #region  Utils */
  const currentContentTypes = watch('contentTypes');
  const currentRule = watch('rule');
  const currentPage = watch('page');
  const currentDatabase = watch('database');

  const refetchPagesDebounced = useDebouncedCallback(
    (search: string | null) => refetchPages({ search, pageSize: 15 }),
    500,
  );
  /* #endregion */

  /* #region Handlers */
  const handleChangeNotionPage = (targetPage: GenericNotionPage) => async () => {
    setSelectedNotionPage(targetPage);
    setNotionDbFilterValue('');

    setValue('page', targetPage);
    setValue('database', null);

    let result;

    if (databasesData?.notionDatabases === undefined) {
      result = await getDatabases({
        variables: {
          pageId: targetPage.id,
          integrationType: NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION,
        },
      });
    } else {
      result = await refetchDatabases({
        pageId: targetPage.id,
        integrationType: NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION,
      });
    }

    const databaseCount = result?.data?.notionDatabases?.length;
    const defaultDatabase = result?.data?.notionDatabases?.find((db) => db.name === 'Sembly Tasks');

    if (!!defaultDatabase) {
      // If the default database found, select it automatically
      setAnchorEl(null);
      setValue('database', defaultDatabase);
      clearErrors('page');
      clearErrors('database');
    } else if (!databaseCount) {
      // If no databases found, create one automatically
      const creationResult = await createNotionDatabase({
        variables: {
          pageId: targetPage.id,
          integrationType: NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION,
        },
        refetchQueries: [
          {
            query: notionDatabases,
            variables: {
              pageId: targetPage.id,
              integrationType: NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION,
            },
          },
        ],
      });

      if (creationResult?.data?.createNotionDatabase?.database) {
        setAnchorEl(null);
        setValue('database', creationResult.data.createNotionDatabase.database);
        clearErrors('page');
        clearErrors('database');
      } else {
        setAnchorEl(null); // Close menu
        onResponsesError(creationResult?.data?.createNotionDatabase?.errors);
      }
    }
  };

  const handleFetchMoreNotionPages = async () => {
    fetchMorePages({
      variables: {
        pageSize: 15,
        startCursor: pagesData?.notionPages?.nextCursor,
        search: notionPageFilterValue || null,
      },
    });
  };

  const handleChangeNotionPageFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setNotionPageFilterValue(search);
    refetchPagesDebounced(search);
  };

  const handleClearNotionPageFilterValue = () => {
    setNotionPageFilterValue('');
    refetchPages({
      pageSize: 15,
      search: null,
    });
  };

  const handleChangeNotionDbFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotionDbFilterValue(event.target.value);
  };

  const handleClearNotionDbFilterValue = () => {
    setNotionDbFilterValue('');
  };

  const handleChangeNotionDatabase = (targetDatabase: GenericNotionDatabase) => () => {
    setAnchorEl(null);
    setValue('database', targetDatabase);
    clearErrors('page');
    clearErrors('database');
  };

  const handleClickOnBackMenu = () => {
    setSelectedNotionPage(null);
  };

  const handleChangeTags = async (
    event: unknown,
    allTagsWithNewOne: (string | string[])[],
    reason: AutocompleteChangeReason,
  ) => {
    const tags = allTagsWithNewOne.map((tag) => tag.toString());
    if (tags.length && reason !== 'remove-option') {
      setValue('keywords', [...tags]);
    }
    clearErrors('keywords');
  };

  const handleDeleteTag = async (itemToDelete: string) => {
    const id = getValues('keywords').find((item) => item === itemToDelete);

    if (!id) return;

    setValue(
      'keywords',
      getValues('keywords').filter((item) => item !== id),
    );
  };

  const handleClickOnCreateNotionDb = async () => {
    if (!selectedNotionPage) {
      throw new Error('No Notion page selected');
    }

    const result = await createNotionDatabase({
      variables: {
        pageId: selectedNotionPage.id,
        integrationType: NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION,
      },
    });

    if (result?.data?.createNotionDatabase?.database) {
      setAnchorEl(null);
      setValue('database', result.data.createNotionDatabase.database);
      clearErrors('page');
      clearErrors('database');
    } else {
      setAnchorEl(null); // Close menu
      onResponsesError(result?.data?.createNotionDatabase?.errors);
    }
  };

  const handleCloseRuleDialog = (event: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return;
    onClose();
    reset();
  };

  const handleClickSubmit = (formValues: NotionAutomationRuleCreatorDialogForm) => {
    if (!formValues.page || !formValues.database) {
      if (!formValues.page?.id) setError('page', { type: 'required' });
      if (!formValues.database?.id) setError('database', { type: 'required' });
      return;
    }

    const commonInput: Omit<
      AddNotionAssignmentsRuleSettingInput | EditNotionAssignmentsRuleSettingInput,
      'id'
    > = {
      contentTypes: [formValues.contentTypes],
      description: formValues.description,
      destinationDatabaseId: formValues.database.id,
      destinationPageId: formValues.page.id,
      isActive: formValues.isActive,
      keywords: formValues.keywords,
      meetingType: formValues.meetingType,
      rule: IntegrationConnectRules[formValues.rule],
    };

    if (editRule) {
      onEdit({
        id: formValues.id,
        ...commonInput,
      });
    } else {
      onCreate(commonInput);
    }

    onClose();
  };
  /* #endregion */

  /* #region  Effects */
  // Set default values for edit rule
  useEffect(() => {
    if (editRule) {
      setValue('id', editRule.id);
      setValue('description', editRule.description);
      setValue('database', editRule.database);
      setValue('page', editRule.page);
      setValue('isActive', editRule.isActive);
      setValue('keywords', editRule.keywords as string[]);
      setValue('meetingType', editRule.meetingType || MeetingTypes.GENERIC);
      setValue('rule', editRule.rule);
      setValue('contentTypes', editRule.contentTypes?.[0]);
    }
  }, [editRule, setValue]);
  /* #endregion */

  /* #region Render Helpers */
  const pageObjects = pagesData?.notionPages.objects || [];
  const hasMoreNotionPages = pagesData?.notionPages.hasNext ?? false;
  const isLoadingNotionPages = pagesNetworkStatus === NetworkStatus.loading;
  const isFetchingNotionPages = pagesNetworkStatus === NetworkStatus.fetchMore;
  const isLoadingNotionDatabases = dbNetworkStatus === NetworkStatus.loading;
  const isRefetchingNotionDatabases =
    dbNetworkStatus === NetworkStatus.refetch || dbNetworkStatus === NetworkStatus.setVariables;
  const destinationValue =
    currentPage && currentDatabase ? `${currentPage.name} [${currentDatabase.name}]` : '';
  const databaseObjects = databasesData?.notionDatabases || [];
  const filteredNotionDatabases = databaseObjects.filter(({ name }) =>
    name.toLocaleLowerCase().includes(notionDbFilterValue.toLocaleLowerCase()),
  );

  const currentFilterValue = () => {
    let result: string[] = [];

    if (currentContentTypes) {
      result.push(KEY_ITEMS_LIST[currentContentTypes]);
    }

    if (currentRule) {
      result.push(RULE_STATUSES[currentRule]);
    }

    if (currentRule === 'FILTERED_BY_KEYWORDS') {
      result.push(`Keywords: ${getValues('keywords').join(', ')}`);
    }

    return result.length ? result.join('; ') : null;
  };

  // Convert MEETING_TYPES to array save, category => isCategory true;
  const meetingTypeOptions: { label: string; value: string | null; isCategory: boolean }[] =
    MEETING_TYPES.flatMap(({ category, elements }) => [
      { label: category, value: null, isCategory: true },
      ...elements.map((element) => ({
        label: element.label,
        value: element.value,
        isCategory: false,
      })),
    ]);
  /* #endregion */

  return (
    <>
      <GenericDialog
        hideTitle
        hideCloseIconButton
        disableTransition
        dialogProps={{ open: true, fullScreen: true, style: { zIndex: 1000 } }}
        onClose={handleCloseRuleDialog}
      >
        <DialogContent className={styles.dialogContent}>
          <div className={styles.header}>
            <img src={semblyLogo} alt="Sembly" className={styles.logo} />
          </div>
          <div className={styles.content}>
            <div className={styles.appLogo}>
              <img src={notionLogo} alt="Notion" width={64} height={64} />
            </div>
            <form className={styles.form} onSubmit={handleSubmit(handleClickSubmit)}>
              <Box position="relative">
                <Tooltip arrow title="Enter Automation Name">
                  <InputBase
                    autoFocus
                    placeholder="Enter Automation Name"
                    classes={{ root: styles.fieldRoot, input: styles.fieldInput }}
                    inputProps={{ maxLength: 255, 'aria-label': 'Enter Automation Name' }}
                    {...register('description', {
                      required: true,
                      maxLength: 255,
                      pattern: /[\w,./_=?-]+/,
                    })}
                  />
                </Tooltip>
              </Box>

              <div className={styles.steps}>
                <AutomationRuleCard
                  disabled
                  title="1. Data Type"
                  description="What would you like to send: notes, tasks, or transcriptions?"
                  value="Tasks"
                  icon={<DataTypeIcon color="action" />}
                />
                <div className={styles.delimiter} role="presentation">
                  —
                </div>
                <AutomationRuleCard
                  title="2. Filter"
                  description="Which meetings group should this be applied to?"
                  icon={<FilterIcon color="action" />}
                  value={currentFilterValue()}
                  onClick={() => setShowFilterDialog(true)}
                />
                <div className={styles.delimiter} role="presentation">
                  —
                </div>
                <AutomationRuleCard
                  title="3. Destination"
                  description="Which destination would you like to use for sending this information?"
                  icon={<DestinationIcon color="action" />}
                  value={!!destinationValue ? destinationValue : null}
                  onClick={() => setShowDestinationDialog(true)}
                />
              </div>

              <GenericDialog
                title="Filter meetings"
                dialogProps={{
                  maxWidth: 'sm',
                  fullWidth: true,
                  keepMounted: true,
                  disablePortal: true,
                  open: showFilterDialog,
                }}
                onClose={() => setShowFilterDialog(false)}
              >
                <DialogContent>
                  <Box mb={2}>
                    <Typography variant="h6" className={styles.labelRule}>
                      Apply to the following Tasks
                    </Typography>
                    <Controller
                      name="contentTypes"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={getValues('contentTypes')}
                      render={({ field }) => (
                        <FormControl fullWidth size="small" variant="filled">
                          <Select fullWidth {...field}>
                            {Object.entries(KEY_ITEMS_LIST).map(([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Box>

                  <Box mb={2}>
                    <Typography variant="h6" className={styles.labelRule}>
                      Rule
                    </Typography>
                    <Controller
                      name="rule"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={getValues('rule')}
                      render={({ field }) => (
                        <FormControl fullWidth size="small" variant="filled">
                          <Select fullWidth {...field}>
                            {Object.entries(RULE_STATUSES).map(([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Box>
                  {currentRule === 'FILTERED_BY_KEYWORDS' && (
                    <Box mb={2}>
                      <Typography variant="h6" className={styles.labelRule}>
                        Keywords in the meeting title
                      </Typography>
                      <Controller
                        name="keywords"
                        control={control}
                        defaultValue={getValues('keywords')}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Tags
                            {...field}
                            all={getValues('keywords')}
                            onChangeTags={handleChangeTags}
                            onDelete={handleDeleteTag}
                          />
                        )}
                      />
                    </Box>
                  )}
                  {currentRule === 'FILTERED_BY_MEETING_TYPE' && (
                    <Box mb={2}>
                      <Typography variant="h6" className={styles.labelRule}>
                        Meeting Type
                      </Typography>
                      <Controller
                        name="meetingType"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={getValues('meetingType')}
                        render={({ field }) => (
                          <FormControl fullWidth size="small" variant="filled">
                            <Select fullWidth {...field}>
                              {meetingTypeOptions.map((item, index) =>
                                item.isCategory ? (
                                  <ListSubheader
                                    key={index}
                                    classes={{ root: styles.listSubHeaderRoot }}
                                  >
                                    {item.label}
                                  </ListSubheader>
                                ) : (
                                  <MenuItem key={index} value={item.value || ''}>
                                    {item.label}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        )}
                      />
                      {formState.errors.meetingType?.type === 'required' && (
                        <FormHelperText className={styles.validationErrorText} variant="outlined">
                          Meeting Type is required
                        </FormHelperText>
                      )}
                    </Box>
                  )}
                </DialogContent>
                <DialogActions className={styles.actions}>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={() => setShowFilterDialog(false)}
                  >
                    Done
                  </Button>
                </DialogActions>
              </GenericDialog>

              <GenericDialog
                title="Destination"
                dialogProps={{
                  maxWidth: 'sm',
                  fullWidth: true,
                  keepMounted: true,
                  disablePortal: true,
                  open: showDestinationDialog,
                }}
                onClose={() => setShowDestinationDialog(false)}
              >
                <DialogContent>
                  <Box mb={2}>
                    <Typography variant="h6" className={styles.labelRule}>
                      Destination
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      variant="filled"
                      placeholder="Select a destination"
                      disabled={isLoadingSuggestedDestination}
                      value={destinationValue}
                      InputProps={{
                        readOnly: true,
                        style: { cursor: 'pointer', paddingRight: 0 },
                        endAdornment: (
                          <InputAdornment position="start">
                            {isLoadingSuggestedDestination ? (
                              <CircularProgress size={20} />
                            ) : (
                              <ArrowDropDownIcon color="action" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{ style: { cursor: 'pointer' } }}
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                    />

                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary">
                        Every meeting will be pushed as a separate page in the database you selected
                        in Destination
                      </Typography>
                    </Box>

                    <Menu
                      variant="menu"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                      classes={{ paper: styles.paper, list: styles.menu }}
                      onClose={() => setAnchorEl(null)}
                    >
                      <div>
                        <div className={styles.sticky}>
                          {selectedNotionPage ? (
                            <>
                              <div className={styles.destinationHeader}>
                                <IconButton
                                  size="small"
                                  className={`${styles.iconButton} destination`}
                                  onClick={handleClickOnBackMenu}
                                >
                                  <BackIcon fontSize="small" />
                                </IconButton>
                                <div className={styles.destinationInner}>
                                  <Typography component="div">
                                    <b>Select a database</b>
                                  </Typography>
                                </div>
                              </div>
                              <Divider />
                              <TextField
                                fullWidth
                                size="small"
                                variant="filled"
                                placeholder="Type here to search databases"
                                className={styles.destinationSearch}
                                value={notionDbFilterValue}
                                InputProps={{
                                  endAdornment: !!notionDbFilterValue && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        size="small"
                                        onClick={handleClearNotionDbFilterValue}
                                      >
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={handleChangeNotionDbFilterValue}
                              />
                            </>
                          ) : (
                            <>
                              <div className={styles.destinationHeader}>
                                <div className={styles.destinationInner}>
                                  <Typography component="div">
                                    <b>Select a page</b>
                                  </Typography>
                                </div>
                              </div>
                              <Divider />
                              <TextField
                                fullWidth
                                size="small"
                                variant="filled"
                                placeholder="Type here to search pages"
                                className={styles.destinationSearch}
                                value={notionPageFilterValue}
                                InputProps={{
                                  endAdornment: !!notionPageFilterValue && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        size="small"
                                        onClick={handleClearNotionPageFilterValue}
                                      >
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={handleChangeNotionPageFilterValue}
                              />
                            </>
                          )}
                          <Divider />
                        </div>
                        {isLoadingNotionPages ||
                        isLoadingNotionDatabases ||
                        isRefetchingNotionDatabases ||
                        isCreatingNotionDb ? (
                          <Box display="flex" alignItems="center" justifyContent="center" py={8}>
                            <CircularProgress size={24} />
                          </Box>
                        ) : (
                          <>
                            {!selectedNotionPage ? (
                              <Box my={1}>
                                {pageObjects.map((page) => (
                                  <MenuItem key={page.id} onClick={handleChangeNotionPage(page)}>
                                    <Box display="flex" minWidth={32}>
                                      {!!page.imageUrl || !!page.emoji ? (
                                        <>
                                          {!!page.imageUrl ? (
                                            <img
                                              loading="lazy"
                                              src={page.imageUrl}
                                              alt={page.name}
                                              className={styles.icon}
                                            />
                                          ) : (
                                            <Twemoji options={{ className: styles.icon }}>
                                              {page.emoji}
                                            </Twemoji>
                                          )}
                                        </>
                                      ) : (
                                        <PageIcon className={styles.icon} color="action" />
                                      )}
                                    </Box>
                                    <Typography component="span" className={styles.textWrap}>
                                      {page.name}
                                    </Typography>
                                  </MenuItem>
                                ))}
                                {hasMoreNotionPages && (
                                  <MenuItem onClick={handleFetchMoreNotionPages}>
                                    <Box display="flex" minWidth={32}>
                                      {isFetchingNotionPages && (
                                        <CircularProgress size={16} style={{ marginRight: 8 }} />
                                      )}
                                    </Box>
                                    <Typography
                                      component="span"
                                      color="primary"
                                      className={styles.textWrap}
                                    >
                                      Load more pages
                                    </Typography>
                                  </MenuItem>
                                )}
                                {!pageObjects.length && (
                                  <Box m={2}>
                                    <Typography variant="body2" color="textSecondary">
                                      No pages found
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            ) : (
                              <Box my={1}>
                                <MenuItem onClick={handleClickOnCreateNotionDb}>
                                  <Box display="flex" minWidth={32}>
                                    <AddIcon className={styles.icon} color="action" />
                                  </Box>
                                  <Typography component="span" className={styles.textWrap}>
                                    Sembly will create a database for you
                                  </Typography>
                                </MenuItem>
                                {filteredNotionDatabases.map((db) => (
                                  <MenuItem key={db.id} onClick={handleChangeNotionDatabase(db)}>
                                    <Box display="flex" minWidth={32}>
                                      <StorageIcon className={styles.icon} color="action" />
                                    </Box>
                                    <Typography component="span" className={styles.textWrap}>
                                      {db.name}
                                    </Typography>
                                  </MenuItem>
                                ))}
                                {!filteredNotionDatabases.length && (
                                  <Box m={2}>
                                    <Typography variant="body2" color="textSecondary">
                                      No databases found
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            )}
                          </>
                        )}
                      </div>
                    </Menu>
                  </Box>
                </DialogContent>
                <DialogActions className={styles.actions}>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={() => setShowDestinationDialog(false)}
                  >
                    Done
                  </Button>
                </DialogActions>
              </GenericDialog>

              {!!formState.errors && (
                <Box my={2} display="flex" flexDirection="column" gridGap={6}>
                  {formState.errors.description?.type === 'required' && (
                    <Alert color="warning">
                      <Typography variant="body1">Automation Name is required</Typography>
                    </Alert>
                  )}
                  {formState.errors.description?.type === 'maxLength' && (
                    <Alert color="warning">
                      <Typography variant="body1">
                        Automation Name cannot be longer than 255 characters
                      </Typography>
                    </Alert>
                  )}
                  {formState.errors.description?.type === 'pattern' && (
                    <Alert color="warning">
                      <Typography variant="body1">Please enter a valid Automation Name</Typography>
                    </Alert>
                  )}
                  {formState.errors.keywords?.type === 'required' && (
                    <Alert color="warning">
                      <Typography variant="body1">Keywords is required</Typography>
                    </Alert>
                  )}
                  {formState.errors.meetingType?.type === 'required' && (
                    <Alert color="warning">
                      <Typography variant="body1">Meeting Type is required</Typography>
                    </Alert>
                  )}
                  {(formState.errors.page?.type === 'required' ||
                    formState.errors.database?.type === 'required') && (
                    <Alert color="warning">
                      <Typography variant="body1">Destination is required</Typography>
                    </Alert>
                  )}
                </Box>
              )}

              <div className={styles.dialogActions}>
                <Box flexGrow={1}>
                  <Button variant="outlined" onClick={onClose}>
                    Back
                  </Button>
                </Box>
                <Box display="flex" flexGrow={0} gridGap={8}>
                  <Button disableElevation color="primary" variant="contained" type="submit">
                    Complete
                  </Button>
                </Box>
              </div>
            </form>
          </div>
        </DialogContent>
      </GenericDialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: 0,
  },
  paper: {
    minWidth: '40ch',
    maxWidth: '536px',
    maxHeight: '60vh',
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    borderRadius: theme.shape.borderRadius,
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  destinationHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  destinationInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.75),
  },
  destinationSearch: {
    padding: theme.spacing(1.5, 2),
  },
  labelRule: {
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1),
  },
  page: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  icon: {
    width: 18,
    height: 18,
    display: 'flex',
  },
  iconButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
    '&.destination': {
      position: 'absolute',
      top: '50%',
      left: theme.spacing(1),
      transform: 'translateY(-50%)',
    },
  },
  validationErrorText: {
    color: theme.palette.status.error.color,
  },
  textWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  form: {
    width: 'inherit',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1, 4, 4),
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    flex: 1,
  },
  appLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 128,
    width: 128,
  },
  skeleton: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  logo: {
    flexGrow: 0,
    width: 160,
    height: 48,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 20,
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2, 3),
  },
  steps: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    flexDirection: 'row',
    gap: theme.spacing(2),
    margin: theme.spacing(4, 0),
  },
  delimiter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  fieldRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px dashed ${theme.palette.divider}`,
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    ...theme.typography.h1,
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  fieldInput: {
    textAlign: 'center',
  },
  listSubHeaderRoot: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

export default NotionAssignmentsAutomationRuleCreatorDialog;
