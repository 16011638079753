import { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
// Material UI
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import FilterListIcon from '@material-ui/icons/FilterList';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
import SearchIcon from '@material-ui/icons/Search';
// Lib
import SearchInput from './SearchInput';
import SentimentWidget, { SentimentWidgetDataEntity } from './SentimentWidget';
import { GenericDiarizationItem, GenericInsight } from '../types';

/* #region  Types */
interface SearchResult {
  nodes: NodeListOf<HTMLElement>;
  index: number;
}

export interface MeetingTranscriptViewProps {
  className?: string;
  diarizationItems: GenericDiarizationItem[];
  isFiltered?: boolean;
  isRTLDirected?: boolean;
  meetingHighlights: GenericInsight[];
  meetingLowlights: GenericInsight[];
  search?: string;
  onChangedSearchTerm?: (value: string | null) => void;
  onClickOnFilterParticipans: React.MouseEventHandler<HTMLButtonElement>;
  onJumpedToHighlight?: () => void;
  onJumpedToSearchResult?: () => void;
}
/* #endregion */

export const MeetingTranscriptView: React.FC<MeetingTranscriptViewProps> = ({
  children,
  className = '',
  diarizationItems,
  isFiltered = false,
  isRTLDirected = false,
  meetingHighlights,
  meetingLowlights,
  search,
  onChangedSearchTerm = () => null,
  onClickOnFilterParticipans,
  onJumpedToHighlight = () => null,
  onJumpedToSearchResult = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const history = useHistory();

  const [searchMode, setSearchMode] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult | null>(null);
  const [searchTerm, setSearchTerm] = useState<{ value: string; itemId: string } | null>(null);
  /* #endregion */

  /* #region  Handlers */
  const handleJumpToSentiment = (item: SentimentWidgetDataEntity, event: React.MouseEvent) => {
    event.stopPropagation();
    const target = document.querySelector(`[data-scroll-id="${item.itemId}"]`);
    if (target) {
      handleUpdateSearchTerm(item.text, item.itemId);
      target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      window.addEventListener('click', () => handleClearSearchTerm(), { once: true });
      onJumpedToHighlight();
    }
  };

  const handleJumpToSearchResult = (type: 'prev' | 'next') => () => {
    if (!searchResults) return;
    const resultsLength = searchResults.nodes.length;
    const targetIndex = type === 'next' ? searchResults.index + 1 : searchResults.index - 1;
    let index = targetIndex >= resultsLength ? 0 : targetIndex;
    if (index < 0) index = resultsLength - 1;
    // update state
    setSearchResults((state) => (!state ? null : { nodes: state.nodes, index }));
    // scroll to target
    if (!searchResults || !searchResults.nodes.length) return;
    searchResults.nodes[searchResults.index].scrollIntoView({ block: 'center' });
    onJumpedToSearchResult();
  };

  const handleClearSearchTerm = () => {
    setSearchTerm({ value: '', itemId: '' });
    setSearchMode(false);
    onChangedSearchTerm(null);
  };

  const handleUpdateSearchTerm = (value: string, itemId: string = '') => {
    setSearchTerm({ value, itemId });
    onChangedSearchTerm(value);
  };
  /* #endregion */

  /* #region  Effects */
  // Handle scroll to a dirization item using a query parameter
  useLayoutEffect(() => {
    if (!search || !diarizationItems.length) return;

    const searchParams = new URLSearchParams(search);
    const id = searchParams.get('id');
    const target = document.querySelector(`[data-scroll-id="${id}"]`);

    if (!id || !target) return;

    target.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    target.classList.add('highlighted');

    const handleRemoveHighlighting = () => {
      target.classList.remove('highlighted');
      searchParams.delete('id');
      history.replace({ search: searchParams.toString() });
    };

    document.addEventListener('mousedown', handleRemoveHighlighting);

    return () => {
      document.removeEventListener('mousedown', handleRemoveHighlighting);
    };
  }, [diarizationItems.length, search, history]);

  // Handle scroll to a search item using a search parameter
  useLayoutEffect(() => {
    if (!searchTerm || !diarizationItems) {
      if (!searchResults?.nodes.length) setSearchResults(null);
      return;
    }
    setTimeout(() => {
      const nodes = document.querySelectorAll('mark');
      setSearchResults({ nodes, index: 0 });
    }, 100);
  }, [diarizationItems, searchTerm, searchResults?.nodes.length]);
  /* #endregion */

  /* #region  Memorized  Data */
  const sentiments = useMemo(() => {
    let result: SentimentWidgetDataEntity[] = [];

    function extractPeaks(item: GenericInsight, type: 'highlight' | 'lowlight') {
      if (!item?.diarizationItem) return;
      return {
        type,
        id: item.id,
        itemId: item.diarizationItem.id,
        sentiment: item.sentiment,
        startTime: item.diarizationItem.startTime,
        text: item.text,
      };
    }

    meetingHighlights.forEach((item) => {
      const highlight = extractPeaks(item, 'highlight');
      if (!!highlight) result.push(highlight);
    });

    meetingLowlights.forEach((item) => {
      const lowlight = extractPeaks(item, 'lowlight');
      if (!!lowlight) result.push(lowlight);
    });

    return result;
  }, [meetingHighlights, meetingLowlights]);
  /* #endregion */

  /* #region  Render Helpers */
  const searchResultCount = searchResults?.nodes.length || 0;
  const searchResultIndex = !searchResults ? 0 : searchResults.index + 1;
  const hasSearchTerm = !!searchTerm;
  const hasSearchResult = searchResultCount > 0;
  const searchResultText = hasSearchResult ? `${searchResultIndex}/${searchResultCount}` : '0/0';
  /* #endregion */

  return (
    <div className={className}>
      {!!sentiments.length && (
        <Box style={{ direction: isRTLDirected ? 'rtl' : 'inherit' }}>
          <Box mt={3} mb={2}>
            <Typography variant="h6">Highlights</Typography>
          </Box>
          <SentimentWidget data={sentiments} onClick={handleJumpToSentiment} />
        </Box>
      )}

      <div className={styles.toolbar} style={{ direction: isRTLDirected ? 'rtl' : 'inherit' }}>
        <Typography variant="h6">
          Transcription{' '}
          <Link
            color="textSecondary"
            target="_blank"
            rel="noopener noreferrer"
            title="Help"
            href="https://helpdesk.sembly.ai/hc/en-us/articles/10497789200017-Manage-Meeting-Transcript"
            style={{ display: 'inline-block', lineHeight: '1rem', fontSize: '1rem' }}
          >
            <QuestionMarkIcon fontSize="inherit" />
          </Link>
        </Typography>
        <Box display="flex" alignItems="center">
          {searchMode ? (
            <SearchInput
              clearButton
              navButtons
              hasNext={hasSearchResult}
              hasPrev={hasSearchResult}
              searchResultCount={hasSearchTerm ? searchResultText : ''}
              onClear={handleClearSearchTerm}
              onChange={handleUpdateSearchTerm}
              onClickOnPrev={handleJumpToSearchResult('prev')}
              onClickOnNext={handleJumpToSearchResult('next')}
            />
          ) : (
            <IconButton onClick={() => setSearchMode(true)}>
              <SearchIcon />
            </IconButton>
          )}
          <IconButton onClick={onClickOnFilterParticipans}>
            <Badge color="primary" variant="dot" overlap="circular" invisible={!isFiltered}>
              <FilterListIcon />
            </Badge>
          </IconButton>
        </Box>
      </div>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    top: -1,
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.paper,
    zIndex: 1,
  },
}));

export default MeetingTranscriptView;
