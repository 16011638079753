import { useState } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { parseISO } from 'date-fns';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
import SearchIcon from '@material-ui/icons/SearchSharp';
// GraphQL Queries and Types
import query from '../graphql/queries/UserMeetingsPaginated.graphql';
import { MeetingStatuses, UserMeetingsPaginated, UserMeetingsPaginatedVariables } from '../types';
import { MEETING_TYPES } from '../constants';
import { formatInTimeZone } from '../utils';

export interface MeetingsListContainerProps {
  processing: boolean;
  onSelect: (id: string) => void;
}

export const MeetingsListContainer: React.VFC<MeetingsListContainerProps> = ({
  processing,
  onSelect,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [filterValue, setFilterValue] = useState('');

  const { data, fetchMore, refetch, networkStatus } = useQuery<
    UserMeetingsPaginated,
    UserMeetingsPaginatedVariables
  >(query, {
    variables: { page: 1, perPage: 10, search: '', statuses: [MeetingStatuses.submitted] },
    notifyOnNetworkStatusChange: true,
  });
  /* #endregion */

  /* #region  Handlers */
  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    const term = value ? value.toLowerCase() : '';
    refetch({ page: 1, perPage: 10, search: term, statuses: [MeetingStatuses.submitted] });
  }, 1000);

  const handleChangeFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setFilterValue(term);
    handleSearchDebounced(term);
  };

  const handleClearFilterValue = () => {
    setFilterValue('');
    refetch({ page: 1, perPage: 10, search: '', statuses: [MeetingStatuses.submitted] });
  };

  const handleClickOnItem = (id: string) => () => {
    onSelect(id);
  };

  const handleFetchMoreItems = () => {
    const currentPage = data?.meetingsPaginated?.page ?? 1;
    fetchMore({
      variables: {
        perPage: 10,
        search: filterValue,
        page: currentPage + 1,
        statuses: [MeetingStatuses.submitted],
      },
    });
  };
  /* #endregion */

  /* #region  Render Helpers */
  const items = data?.meetingsPaginated?.objects || [];
  const isLoadingInitial = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const isSettingVariables = networkStatus === NetworkStatus.setVariables;
  const isRefetching = networkStatus === NetworkStatus.refetch || isSettingVariables;
  const hasMoreItems = data?.meetingsPaginated?.hasNext || false;
  /* #endregion */

  return (
    <div className={styles.root}>
      {isLoadingInitial || processing ? (
        <Box display="flex" justifyContent="center" my={8}>
          <CircularProgress />
        </Box>
      ) : (
        <div className={styles.container}>
          <div className={styles.search}>
            <TextField
              autoFocus
              fullWidth
              size="small"
              variant="filled"
              placeholder="Search meetings"
              disabled={data === undefined}
              value={filterValue}
              onChange={handleChangeFilterValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" color="disabled" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!!isRefetching ? (
                      <CircularProgress size={18} />
                    ) : (
                      <>
                        {!!filterValue && (
                          <IconButton size="small" onClick={handleClearFilterValue}>
                            <CloseIcon fontSize="small" color="primary" />
                          </IconButton>
                        )}
                      </>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <TableContainer>
              <Table className={styles.table}>
                {!isSmallScreen && (
                  <TableHead>
                    <TableRow>
                      <TableCell>Meeting</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {items.map((meeting) => {
                    let meetingType: string | null = null;

                    for (const category of MEETING_TYPES) {
                      const element = category.elements.find(
                        (el) => el.value === meeting.meetingType,
                      );
                      if (element) {
                        meetingType = element.label;
                        break;
                      }
                    }

                    return (
                      <TableRow key={meeting.id}>
                        <TableCell width="auto">{meeting.title}</TableCell>
                        <TableCell width="auto">
                          {meeting.startedAt
                            ? formatInTimeZone(parseISO(meeting.startedAt), 'PPpp')
                            : ''}
                        </TableCell>
                        <TableCell>{meetingType || 'General'}</TableCell>
                        <TableCell align="right">
                          <Button
                            disableElevation
                            size="small"
                            color="inherit"
                            variant="contained"
                            className={styles.button}
                            onClick={handleClickOnItem(meeting.id)}
                          >
                            <Typography noWrap component="span" variant="body1">
                              Add
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {items.length === 0 && !filterValue && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography align="center">
                          Currently, there are no meetings available.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {hasMoreItems && !isLoadingMore && (
              <Box display="flex" justifyContent="center" m={2}>
                <Button size="small" variant="outlined" onClick={handleFetchMoreItems}>
                  <Typography noWrap component="span" variant="body1">
                    Load more
                  </Typography>
                </Button>
              </Box>
            )}
            {isLoadingMore && (
              <Box display="flex" justifyContent="center" m={2}>
                <CircularProgress size={18} />
              </Box>
            )}
            {!items.length && !!filterValue && !isRefetching && (
              <Box mt={4} mb={8}>
                <Typography align="center">No results found for "{filterValue}".</Typography>
              </Box>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  chip: {
    display: 'inline-block',
    textAlign: 'center',
    borderRadius: '2em',
    padding: theme.spacing(0.5, 2),
    width: '100%',
    background: theme.palette.highlight.light,
    color: theme.palette.indication.contrastText,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  search: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 0),
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  button: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
  table: {
    '& td': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    '& th': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    '& td:not(:nth-child(1))': {
      whiteSpace: 'nowrap',
    },
    '& td:nth-child(1)': {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      // small screen styles render the table as a list
      '& th, & td': {
        display: 'block',
        padding: theme.spacing(1, 0),
        textAlign: 'left',
        borderBottom: 'none',
        '&:last-child': {
          paddingBottom: theme.spacing(3),
          marginBottom: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
      '& th': {
        display: 'none',
      },
    },
  },
}));

export default MeetingsListContainer;
