export type IntegrationAppsDataType = {
  category: string;
  title: string;
  description: string;
  categories: string[];
  features: string[];
};

export const INTEGRATION_APPS: Record<string, IntegrationAppsDataType> = {
  sheets: {
    category: 'Analytics',
    title: 'Google Sheets',
    description:
      'A web-based spreadsheet program offered by Google as part of its complete office suite to simplify data management and collaboration',
    categories: ['Meeting Notes', 'Tasks'],
    features: [
      'Send meeting notes along with meeting metadata as a row to selected sheet and tab',
      'Send tasks enriched with all needed fields as rows to selected sheet and tab',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'google-docs': {
    category: 'Document Management',
    title: 'Google Docs',
    description:
      'A web-based document management program offered by Google as part of its complete office suite to simplify online documents management and collaboration',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as a new document per meeting',
      'Send transcriptions along with meeting metadata as a new document per meeting',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  slack: {
    category: 'Collaboration',
    title: 'Slack Meeting Notes',
    description:
      'A cloud-based team collaboration tool that streamlines communication and project management across any organization',
    categories: ['Meeting Notes'],
    features: [
      'Send meeting notes along with meeting metadata as messages to selected channel',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'slack-transcriptions': {
    category: 'Collaboration',
    title: 'Slack Transcriptions',
    description:
      'A cloud-based team collaboration tool that streamlines communication and project management across any organization',
    categories: ['Transcription'],
    features: [
      'Send transcriptions along with meeting metadata as messages to selected channel',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'slack-workspace': {
    category: 'Collaboration',
    title: 'Slack',
    description:
      'A cloud-based team collaboration tool that streamlines communication and project management across any organization',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as messages to selected channel',
      'Send transcriptions along with meeting metadata as messages to selected channel',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'microsoft-teams': {
    category: 'Collaboration',
    title: 'Microsoft Teams',
    description:
      'An integrated workspace within Microsoft 365, facilitating chat, meetings, and collaboration across teams',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as posts to selected channel',
      'Send transcriptions along with meeting metadata as posts to selected channel',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  monday: {
    category: 'Project Management',
    title: 'Monday',
    description:
      'A versatile project management tool designed to enhance team productivity and streamline workflows efficiently',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as items to selected board. Board for storing notes should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Send tasks enriched with all needed fields as items to selected board. Board for storing tasks should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Send transcriptions along with meeting metadata as items to selected board. Board for storing transcriptions should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  notion: {
    category: 'Project Management',
    title: 'Notion',
    description:
      'An all-in-one workspace for note-taking, project management, and task organization to streamline team collaboration',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as items to selected database. Database for storing notes should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Send tasks enriched with all needed fields as items to selected board. Database for storing tasks should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Send transcriptions along with meeting metadata as items to selected database. Database for storing transcriptions should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'notion-assignments': {
    category: 'Project Management',
    title: 'Notion Assignments',
    description:
      'An all-in-one workspace for note-taking, project management, and task organization to streamline team collaboration',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields as items to selected board. Database for storing tasks should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'notion-meeting-notes': {
    category: 'Project Management',
    title: 'Notion Meeting Notes',
    description:
      'An all-in-one workspace for note-taking, project management, and task organization to streamline team collaboration',
    categories: ['Meeting Notes'],
    features: [
      'Send meeting notes along with meeting metadata as items to selected database. Database for storing notes should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'notion-transcriptions': {
    category: 'Project Management',
    title: 'Notion Transcriptions',
    description:
      'An all-in-one workspace for note-taking, project management, and task organization to streamline team collaboration',
    categories: ['Transcription'],
    features: [
      'Send transcriptions along with meeting metadata as items to selected database. Database for storing transcriptions should be created before setting up an automation (all needed columns will be automatically created after the first automation run)',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  airtable: {
    category: 'Project Management',
    title: 'Airtable',
    description:
      'A cloud-based platform combining databases and spreadsheets to organize, collaborate, and manage projects dynamically',
    categories: ['Meeting Notes', 'Tasks'],
    features: [
      'Send meeting notes along with meeting metadata as records to selected board and table',
      'Send tasks enriched with all needed fields as records to selected board and table',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  asana: {
    category: 'Project Management',
    title: 'Asana',
    description:
      'A project and task management tool designed to help teams organize, track, and manage their work efficiently',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields to selected project in a first column',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  jira: {
    category: 'Project Management',
    title: 'Jira',
    description:
      'A powerful project management tool for teams to plan, track, and manage agile software development projects',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields to board within selected project in a first column',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  confluence: {
    category: 'Project Management',
    title: 'Confluence',
    description:
      'An all-in-one place for taking notes, planing projects, and organizing knowledge to streamline team collaboration',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as pages to a default Sembly space in your workspace. Space for storing notes will be created automatically on the first run of the automation',
      'Send transcriptions along with meeting metadata as pages to a default Sembly space in your workspace. Space for storing transcriptions will be created automatically on the first automation run of the automation',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  clickup: {
    category: 'Project Management',
    title: 'Clickup',
    description:
      'A cloud-based collaboration and project management tool aimed at boosting productivity across all types of teams',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata to default Sembly list (auto-created with the first meeting notes sent)',
      'Send tasks enriched with all needed fields to default Sembly list (auto-created with the first task sent)',
      'Send transcriptions along with meeting metadata to default Sembly list (auto-created with the first meeting notes sent)',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  wrike: {
    category: 'Project Management',
    title: 'Wrike',
    description:
      'A cloud-based work management platform aimed at maximizing productivity across all types of teams',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields to default Sembly list (auto-created with the first task sent)',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  trello: {
    category: 'Project Management',
    title: 'Trello',
    description:
      'A visual project management tool that uses boards, lists, and cards to organize tasks and collaborate on projects',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields to selected board in a first column',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'google-tasks': {
    category: 'Personal Productivity',
    title: 'Google Tasks',
    description:
      'A straightforward task management tool integrated with Gmail and Google Calendar to help organize your to-do lists',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields as todos',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'microsoft-todo': {
    category: 'Personal Productivity',
    title: 'Microsoft Todo',
    description:
      'A task management app that integrates with Outlook for a seamless to-do list, task, and reminder experience',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields as todos',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  todoist: {
    category: 'Personal Productivity',
    title: 'Todoist',
    description:
      'A task manager and to-do list app designed to help individuals and teams organize, plan, and collaborate on projects',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields as todos',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  dropbox: {
    category: 'Document Management',
    title: 'Dropbox',
    description:
      'A leading cloud storage service that offers file synchronization, personal cloud, and client software to streamline data sharing',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as paper docs. Meeting notes will be pushed to folder "Sembly Meeting Notes" which will be automatically created if it does not exist',
      'Send transcriptions along with meeting metadata as paper docs. Transcriptions will be pushed to folder "Sembly Transcriptions" which will be automatically created if it does not exist',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  onedrive: {
    category: 'Document Management',
    title: 'Microsoft OneDrive',
    description:
      'A file hosting and synchronization service by Microsoft, offering seamless access and sharing of files across devices',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as txt files. Meeting notes will be pushed to folder "Sembly Meeting Notes" which will be automatically created if it does not exist',
      'Send transcriptions along with meeting metadata as txt files. Transcriptions will be pushed to folder "Sembly Transcriptions" which will be automatically created if it does not exist',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'microsoft-sharepoint': {
    category: 'Document Management',
    title: 'Microsoft Sharepoint',
    description:
      'A web-based collaborative platform integrated with Microsoft Office for document management and storage',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as txt files to selected site. Meeting notes will be pushed to folder "Sembly Meeting Notes" which will be automatically created if it does not exist',
      'Send transcriptions along with meeting metadata as txt files to selected site. Transcriptions will be pushed to folder "Sembly Transcriptions" which will be automatically created if it does not exist',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  gdrive: {
    category: 'Document Management',
    title: 'Google Drive',
    description:
      'A secure cloud storage service that enables file storage, sharing, and collaborative editing from any device',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as txt files. Meeting notes will be pushed to folder "Sembly Meeting Notes" which will be automatically created if it does not exist',
      'Send transcriptions along with meeting metadata as txt files. Transcriptions will be pushed to folder "Sembly Transcriptions" which will be automatically created if it does not exist',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  salesforce: {
    category: 'CRM',
    title: 'Salesforce',
    description:
      'A powerful customer relationship management (CRM) platform designed to enhance sales, marketing, and customer service',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding contacts in Activity tab. Meetings will be pushed as Calendar events. If contact does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks inside corresponding contacts, accounts and deals in Activity tab. If contact does not exist, it will be automatically created',
      'Send transcriptions along with meeting metadata as meetings inside corresponding contacts in Activity tab. Meetings will be pushed as Calendar events. If contact does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  hubspot: {
    category: 'CRM',
    title: 'HubSpot',
    description:
      'An all-in-one inbound marketing, sales, and service platform designed to help businesses grow efficiently',
    categories: ['Meeting Notes', 'Tasks'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding contacts and companies in Activity tab. If contact does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks inside corresponding contacts and companies in Activity tab. If contact does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'zoho-crm': {
    category: 'CRM',
    title: 'Zoho CRM',
    description:
      'A cloud-based CRM solution tailored to attract, retain, and satisfy customers to grow your business',
    categories: ['Meeting Notes', 'Tasks'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding contacts and deals in Closed Activities tab. If contact does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks inside corresponding contacts and deals in Open Activities tab. If contact does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'copper-oauth': {
    category: 'CRM',
    title: 'Copper',
    description:
      'A customer relationship management software package developed by Microsoft, focusing on enhancing customer relations',
    categories: ['Meeting Notes', 'Tasks'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding people, companies and pipelines. If person does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks inside corresponding people, companies and pipelines. If person does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'pipedrive-oauth': {
    category: 'CRM',
    title: 'Pipedrive',
    description:
      'A sales management tool designed to help small teams manage intricate or lengthy sales processes',
    categories: ['Meeting Notes', 'Tasks'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding contacts and deals. If contact does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks inside corresponding contacts and deals. If contact does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'dynamics-crm': {
    category: 'CRM',
    title: 'Microsoft Dynamics CRM',
    description:
      'A customer relationship management software package developed by Microsoft, focusing on enhancing customer relations',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding contacts. If contact does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks assigned to corresponding contacts. If contact does not exist, it will be automatically created',
      'Send transcriptions along with meeting metadata as meetings inside corresponding contacts. If contact does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  'sugar-crm': {
    category: 'CRM',
    title: 'SugarCRM',
    description:
      'A comprehensive CRM solution that empowers businesses to create extraordinary customer relationships with the most innovative and affordable CRM solution in the market',
    categories: ['Meeting Notes', 'Tasks'],
    features: [],
  },
  freshsales: {
    category: 'CRM',
    title: 'Freshsales',
    description:
      'A full-fledged sales CRM software for businesses who want to attract, engage, nurture, and convert leads efficiently',
    categories: ['Tasks'],
    features: [
      'Send tasks enriched with all needed fields as tasks inside corresponding contacts, accounts and deals in Activities tab. If contact does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  insightly: {
    category: 'CRM',
    title: 'Insightly',
    description:
      'A CRM and project management software designed to help businesses manage customer interactions and build relationships',
    categories: ['Meeting Notes', 'Tasks'],
    features: [],
  },
  'zendesk-sell': {
    category: 'CRM',
    title: 'Zendesk Sell',
    description:
      'A sales CRM software built to enhance productivity, processes, and pipeline visibility for sales teams',
    categories: ['Meeting Notes', 'Tasks'],
    features: [],
  },
  'close-oauth': {
    category: 'CRM',
    title: 'Close',
    description:
      'A CRM designed specifically for startups and SMBs, focusing on communication acceleration and sales efficiency. Close CRM allows for task prioritization and deadline setting, helping sales reps manage their daily to-do lists effectively. Additionally, tasks can be automated based on triggers within the sales pipeline, such as setting a follow-up task after a call or when a lead moves to a new stage in the sales process.',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as activity events to corresponding contacts. If contact does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks assigned to corresponding contacts. If contact does not exist, it will be automatically created',
      'Send transcriptions along with meeting metadata as activity events to corresponding contacts. If contact does not exist, it will be automatically created',
    ],
  },
  teamleader: {
    category: 'CRM',
    title: 'Teamleader',
    description: 'CRM, invoices, quotations and projects, to keep your business in one place',
    categories: ['Meeting Notes', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding contacts. If contact does not exist, it will be automatically created',
      'Send transcriptions along with meeting metadata as meetings inside corresponding contacts. If contact does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  attio: {
    category: 'CRM',
    title: 'Attio',
    description:
      'A next generation of CRM, designed to be data-driven, customizable and intuitively collaborative',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding contacts. If contact does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks assigned to corresponding contacts. If contact does not exist, it will be automatically created',
      'Send transcriptions along with meeting metadata as meetings inside corresponding contacts. If contact does not exist, it will be automatically created',
      'Filter by team, meeting type, organiser and keywords',
    ],
  },
  basecamp: {
    category: 'Project Management',
    title: 'Basecamp',
    description:
      'Basecamp is a popular project management and team collaboration platform designed to simplify project organization and communication. It offers a centralized space where teams can manage tasks, share files, track progress, and discuss ideas. Basecamp’s straightforward interface provides tools like to-do lists, message boards, file storage, and real-time group chats, making it easy for teams to stay aligned and on track.',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as docs into the project',
      'Send tasks enriched with all needed fields as todos into the project',
      'Send transcriptions along with meeting metadata as docs into the project',
    ],
  },
  teamwork: {
    category: 'Project Management',
    title: 'Teamwork',
    description:
      'Teamwork is a comprehensive project management platform designed to help teams collaborate, plan, and execute projects more efficiently. Tailored for businesses of all sizes, it offers tools for task management, time tracking, project scheduling, and team collaboration. Teamwork provides a centralized workspace where users can create tasks, assign them to team members, set deadlines, and monitor progress through customizable project boards, Gantt charts, and detailed reporting dashboards.',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as Notebooks in the tab inside project. The new tab will be automatically created',
      'Send tasks enriched with all needed fields as list of Tasks in the project. A new project will be automatically created',
      'Send transcriptions along with meeting metadata as Notebooks in the tab inside project. The new tab will be automatically created',
    ],
  },
  'capsule-crm': {
    category: 'CRM',
    title: 'Capsule CRM',
    description:
      'Capsule CRM is a cloud-based customer relationship management (CRM) platform designed to help small and medium-sized businesses manage customer interactions, sales processes, and relationships efficiently. It provides tools for organizing and tracking contacts, managing leads and sales opportunities, and automating workflows. Capsule CRM allows users to categorize and segment their contacts, track communication history, and set tasks and reminders to ensure follow-ups.',
    categories: ['Meeting Notes', 'Tasks', 'Transcription'],
    features: [
      'Send meeting notes along with meeting metadata as meetings inside corresponding contacts. If contact does not exist, it will be automatically created',
      'Send tasks enriched with all needed fields as tasks assigned to corresponding contacts. If contact does not exist, it will be automatically created',
      'Send transcriptions along with meeting metadata as meetings inside corresponding contacts. If contact does not exist, it will be automatically created',
    ],
  },
};

export default INTEGRATION_APPS;
